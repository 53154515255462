import SimpleCard from "../components/SimpleCard";

const HomePage = () => {
    return (
        <div className="container bg-white h-full px-2 sm:px-4 pt-8 pb-4">
            <SimpleCard
                title={"JSON Formatter & Validator"}
                description={"Prettify JSON data with this tool. It will make your JSON data more readable and easy to understand."}
                btnTitle={"Go to JSON Formatter & Validator"}
                btnLink={"/json-prettify"}
            />
        </div>
    );
}

export default HomePage;