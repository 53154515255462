import { useNavigate } from "react-router-dom";

const SimpleCard = ({ title, description, btnTitle, btnLink }) => {
    const navigate = useNavigate();

    return (
        <div className="border border-slate-300 rounded-md p-4">
            {title && <div className="txt-title my-2">{title}</div>}

            <p className="txt-content my-2">{description}</p>

            {btnTitle && <div className="mt-8 flex justify-end">
                <button className="btn-primary"
                onClick={() => {
                    if(btnLink) {
                        navigate(btnLink);
                    }
                }} 
                >{btnTitle}</button>
            </div>
            }

        </div>
    );
}

export default SimpleCard;