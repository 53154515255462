import { useState, useRef, useEffect } from "react";
import { GoCopy } from "react-icons/go";

const ToolsJsonPage = () => {

    const [inputStr, setInputStr] = useState("");
    const [resultStr, setResultStr] = useState("");
    const [errorStr, setErrorStr] = useState("");
    const [spaceOption, setSpaceOption] = useState(4);
    const [showCopiedMsg, setShowCopiedMsg] = useState(false);

    const resultRef = useRef(null);

    useEffect(() => {
        document.title = "JSON Formatter & Validator";
    }, []);

    const spaceOptions = [
        { value: 2, label: "2 spaces" },
        { value: 4, label: "4 spaces" },
        { value: 8, label: "8 spaces" },
        { value: 1, label: "1 tab" }
    ]

    const processJson = () => {
        setErrorStr("");
        setResultStr("");
        try {
            const jsonObj = JSON.parse(inputStr);
            setResultStr(JSON.stringify(jsonObj, null, spaceOption===1?"\t":spaceOption));
        } catch (error) {
            setErrorStr(error.message)
        }
    }

    const handleCopy = () => {
        navigator.clipboard.writeText(resultStr)
            .then(() => {
            })
            .catch((err) => {
                resultRef.current.select();
                document.execCommand("copy");
            });
        setShowCopiedMsg(true);
        setTimeout(() => {
            setShowCopiedMsg(false);
        }, 2000);
    }

    return <div className="container bg-slate-200 h-full px-2 sm:px-4 pt-2 pb-4 overflow-auto custom-scrollbar">
        <div className="pt-1 txt-title w-full text-center !text-slate-500">
            Paste JSON data and process it.
        </div>

        <div className="p-1">
            <div className="txt-subtitle text-white mb-1">JSON Data:</div>
            <div>
                <textarea className="w-full min-h-[220px] max-h-[220px] px-2 py-2 bg-white rounded-lg
                    focus:border-none text-[16px] font-light text-slate-500 font-regular custom-scrollbar"
                    placeholder='{ "field" : "data" }'
                    value={inputStr}
                    onChange={(e) => setInputStr(e.target.value)}>

                </textarea>
            </div>
        </div>

        <div className="w-full flex items-center">
            <div className="grow">
                <div className="flex items-center">
                    <div className="txt-normal mb-1">Indentation:</div>
                    <select className="ml-2 w-[100px] bg-white rounded-md px-2 py-1"
                        value={spaceOption}
                        onChange={(e) => setSpaceOption(Number(e.target.value))}>
                        {spaceOptions.map((option, index) => {
                            return <option key={index} value={option.value}>{option.label}</option>
                        })}
                    </select>
                </div>
                
            </div>
            <button className="ml-2 btn-primary w-[200px]"
                onClick={() => {
                    processJson();
                }}>Process</button>
        </div>

        { errorStr && <div className="mt-4">
            <div className="txt-subtitle">Error:</div>
            <div className="text-red-600 whitespace-pre-line">{errorStr}</div> 
        </div>}

        {resultStr && <div className="mt-4">
            <div className="flex items-center mb-1">
                <div className="txt-subtitle">Result:</div>
                <div className="grow"></div>
                { showCopiedMsg && <div className="text-green-600 mr-2 text-sm">Copied to clipboard</div>}
                <button className="btn-plain-primary !px-4 !py-0.5 flex items-center"
                    onClick={() => {
                        handleCopy();
                    }}>
                        <GoCopy className="mr-1" />
                        Copy
                    </button>
            </div>

            <div>
                <textarea
                    ref={resultRef}
                    className="w-full min-h-[340px] max-h-[600px] px-2 py-2 bg-white rounded-lg
                    focus:border-none text-[16px] font-light text-slate-500 font-regular custom-scrollbar"
                    value={resultStr}
                >
                </textarea>
            </div>
        </div>
        }
    </div>
}

export default ToolsJsonPage;