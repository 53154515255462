

const PrivacyPage = () => {
    const contactUsLink = 'https://forms.gle/5i5sbmkCaceFPqyw8'
    return (
        <div className="container bg-slate-50 h-full px-2 sm:px-4 pt-2 pb-4 overflow-auto custom-scrollbar">
            <div className="pt-1 txt-title w-full text-center !text-slate-500">
                Privacy Policy
            </div>
            <div className="text-slate-400 text-center italic">
                Last updated: December 15th 2024.
            </div>

            <div className="txt-normal my-2">
                This privacy policy attempts to outline how Always Simple websites collect and use information from their users.
            </div>

            <div className="txt-subtitle mt-6 mb-1">
                Logs
            </div>
            <div className="txt-normal mb-1">
                Like most other websites, Always Simple websites collect non-personal information about users whenever they access them. These logs may include the browser name, the type of computer and technical information about the means of connection to the site, such as the operating system, the Internet service providers utilized and other similar information.
            </div>

            <div className="txt-subtitle mt-6 mb-1">
                Cookies
            </div>
            <div className="txt-normal mb-1">
                Cookies are files with small amount of data which are sent to your browser from a website and stored on your computer's hard drive. Always Simple websites uses them to to track preferences and AB testing experiments. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.
            </div>

            <div className="txt-subtitle mt-6 mb-1">
                Data
            </div>
            <div className="txt-normal mb-1">
                With the exception of deliberately submitted contact requests and error reports, Always Simple websites do not collect or retain any data submitted by users. Any contact requests or error reports submitted will be kept confidential and deleted upon request.
            </div>

            <div className="txt-subtitle mt-6 mb-1">
                Third Party
            </div>
            <div className="txt-normal mb-1">
                Always Simple websites use reputable third party vendors to provide traffic analytics, security, and advertising services.
            </div>

            <div className="txt-subtitle mt-6 mb-1">
                Traffic Analytics
            </div>
            <div className="txt-normal mb-1">
                Traffic analysis and monitoring of Always Simple websites is provided by Google Analytics. This information is used to direct resources, plan maintenance windows and improve usability. Google uses cookies and may use the data it collects to contextualize and personalize the ads of its own advertising network.
            </div>

            <div className="txt-subtitle mt-6 mb-1">
                Security
            </div>
            <div className="txt-normal mb-1">
                Additional security measures on Always Simple websites are enabled by CloudFlare. To achieve this CloudFlare, like Always Simple websites, collects non-personal information about users whenever they access Always Simple websites and/or other sites on the Internet. These logs may include the browser name, the type of computer and technical information about the means of connection to the site, such as the operating system, the Internet service providers utilized and other similar information.
            </div>

            <div className="txt-subtitle mt-6 mb-1">
                Advertising
            </div>
            <div className="txt-normal mb-1">
                Ads appearing on Always Simple websites are delivered to you through our partners Newor Media or BuySellAds Carbon.
            </div>

            <div className="txt-subtitle mt-6 mb-1">
                Changes
            </div>
            <div className="txt-normal mb-1">
                This privacy policy may be revised at any time without notice. We encourage users to frequently check this page for any changes. Your continued use Always Simple websites after any changes or revisions to this policy shall indicate your agreement with the revised privacy policy.
            </div>

            <div className="txt-subtitle mt-6 mb-1">
                Questions?
            </div>
            <div className="txt-normal mb-1">
                If you have any questions about this Privacy Policy, or anything else related to Always Simple web sites, please 
                <a className="ml-2 font-semibold underline text-slate-600" 
                    rel="noreferrer" target="_blank" href={contactUsLink}>contact us</a>.
            </div>
        </div>
    );
}

export default PrivacyPage;