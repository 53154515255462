import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./Layout";
import Home from "./pages/Home";
import ToolsJsonPage from "./pages/ToolJsonPage";
import PrivacyPage from "./pages/PrivacyPage";

import NoPage from "./pages/NoPage";

function App() {
  return (
    <div className="w-full h-[100vh] flex flex-col bg-slate-100">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/json-prettify" element={<ToolsJsonPage />} />
            <Route path="/privacy" element={<PrivacyPage />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App;
