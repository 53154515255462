import { Outlet, Link } from "react-router-dom";

const Layout = () => {
  return (
    <>
      <nav className="bg-white px-2 sm:px-6 border-b">
        <div className="flex h-[52px]">
            <Link to="/">
                <img alt='always-simple-logo' className="h-full max-w-[260px] object-contain" src="/imgs/logo-simple.png"/>
            </Link>

            <Link to="/json-prettify" 
              className="hidden sm:flex hover:bg-slate-200 hover:font-bold hover:underline py-2 px-2 sm:px-4 items-center font-semibold text-color-primary">
                JSON Formatter & Validator
            </Link>

            {/* <div className="hover:bg-slate-200 hover:font-bold hover:underline py-2 px-2">
                <Link to="/">Home</Link>
            </div> */}
        </div>
      </nav>
      <div className="grow overflow-auto flex flex-col items-center">
        <Outlet />
        <div className="w-full bg-slate-800 text-white text-center py-1">
              @2024 <a className="hover:underline" href='/'>Always Simple</a> | <a className="hover:underline" href='/privacy'>Privacy Policy</a>
        </div>
      </div>
    </>
  )
};

export default Layout;